import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Image from 'next/image'
import Carousel from 'react-bootstrap/Carousel'
import { useRouter } from 'next/router'
// import Link from 'next/link'
import styles from '../NewTestimonial/n-testimonial.module.scss'
import { usePageAttributes } from '../../Hooks/usePageAttributes'
import { trackEventWithAuthStatus } from '../../utils/mixpanel'
import { useSelector } from 'react-redux'

function useOutsideAlerter(ref, setTestimonialID) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setTestimonialID(null)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const NewTestimonial = () => {
  const router = useRouter()
  const auth = useSelector((state) => state.auth)

  const { getPageId } = usePageAttributes()
  let buttonId = getPageId() + '-testimonial'

  const redirect = () => {
    let page = 'home_web'
    // switch (router.asPath) {
    //   case '/ms-admissions':
    //     page = 'ms_advising_web'
    //     break
    //   case '/sat-tutoring':
    //     page = 'sat_tutoring'
    //     break
    //   case '/ib-tutoring':
    //     page = 'ib_tutoring_web'
    //     break
    //   case '/ivy-league-undergraduate-admissions':
    //     page = 'ug_advising_web'
    //     break
    //   default:
    //     page = 'home_web'
    // }
    trackEventWithAuthStatus(auth, `click_book_consultation_testimonial_${page}`, { buttonId })

    router.push('/contact')
  }
  const [testimonialID, setTestimonialID] = useState(null)
  const wrapperRef = useRef(null)
  const handleShowVideo = (vimeoID) => {
    // swiper !== undefined && swiper !== null && swiper.autoplay.stop()
    // console.log("handle show video", vimeoID)
    setTestimonialID(vimeoID)
  }
  const handleCloseVideo = () => {
    swiper !== undefined && swiper !== null && swiper.autoplay.start()
    setTestimonialID(null)
  }

  useOutsideAlerter(wrapperRef, setTestimonialID)

  let slideData = [
    {
      img: '/testimonial/pankhuri-image-landscape.jpg',
      m_img: '/testimonial/pankhuri_m.jpg',
      id: 'https://player.vimeo.com/video/689536463?autoplay=1&h=a97cecb20a',
      alt: 'Pankhuri started her journey with collegePass',
    },
    {
      img: '/testimonial/aiden-dutta.jpg',
      m_img: '/testimonial/aiden-dutta_m.jpg',
      id: 'https://player.vimeo.com/video/671414452?autoplay=1&h=09f6c79088',
      alt: "Aiden got admitted to his dream college, 'The University of Pennsylvania.'",
    },
    {
      img: '/testimonial/Pratibha.png',
      m_img: '/testimonial/Pratibha_m.png',
      id: 'https://player.vimeo.com/video/541041438?autoplay=1',
      alt: "Pratibha's son Niraj got admitted to his dream college, 'New York University.'",
    },

    {
      img: '/testimonial/Pratibha.png',
      m_img: '/testimonial/Pratibha_m.png',
      id: 'https://player.vimeo.com/video/938242834?autoplay=1&h=8d910f4e19',
      alt: "Tvisha'",
    },
    {
      img: '/testimonial/Pratibha.png',
      m_img: '/testimonial/Pratibha_m.png',
      id: 'https://player.vimeo.com/video/781899305?autoplay=1&h=9cc5a19433',
      alt: "ANICIA'",
    },
    {
      img: '/testimonial/Pratibha.png',
      m_img: '/testimonial/Pratibha_m.png',
      id: 'https://player.vimeo.com/video/785355673?autoplay=1&h=a3c917544d',
      alt: "TULSI'",
    },

    {
      img: '/testimonial/Pratibha.png',
      m_img: '/testimonial/Pratibha_m.png',
      id: 'https://player.vimeo.com/video/878549358?autoplay=1&h=fa89b1ac35',
      alt: "Anuj'",
    },

    {
      img: '/testimonial/Pratibha.png',
      m_img: '/testimonial/Pratibha_m.png',
      id: 'https://player.vimeo.com/video/832583466?autoplay=1&h=cb22f743c1',
      alt: "Nitin'",
    },
    // {
    //   img: '/testimonial/Sheeja.png',
    //   m_img: '/testimonial/Sheeja_m.png',
    //   id: 'https://player.vimeo.com/video/507858570?autoplay=1',
    //   alt: 'Tulsi has been admitted to the University of Toronto, UBC, UC Berkeley, Kings College London and more.',
    // },
  ]

  return (
    <Fragment>
      <Container
        className="smallTestimonial"
        fluid
        style={{
          backgroundColor: 'rgb(238, 47, 76)',
          color: 'rgb(248, 248, 248)',
          backgroundImage: 'url("/testimonial/testimonials_banner_img.jpg")',
          backgroundSize: 'cover',
        }}
      >
        <Carousel>
          <Carousel.Item>
            <Container>
              <Row>
                <Col className={styles.AidenSection}>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col>
                          <h2>AIDEN IS AT THE IVY LEAGUE</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>
                            CollegePass student Aiden is attending UPenn with a scholarship of USD 160,000, having
                            received multiple offers.
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {router.pathname.startsWith('/google-form') ||
                          router.pathname.startsWith('/google-ivy-league') ||
                          router.pathname.startsWith('/google-ivy-league') ? (
                            <Button id={buttonId} href="#google-contact">
                              Book Your Free Consultation
                            </Button>
                          ) : (
                            <Button id={buttonId} onClick={redirect}>
                              Book Your Free Consultation
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col className={styles.ugtestiVideoThumb}>
                          <Row>
                            <Col className={styles.ugVideoThumb}>
                              <Image
                                src="/1.jpg"
                                width="500"
                                height="300"
                                //className={}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className={styles.ugVideoIcon}>
                              <div
                                onClick={() => {
                                  handleShowVideo(slideData[1].id)
                                }}
                              >
                                <Image src="/play-button-cp.svg" width="46" height="46" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <Container>
              <Row>
                <Col className={styles.AidenSection}>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col>
                          <h2>PANKHURI IS AT STANFORD UNIVERSITY</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>
                            CollegePass student Pankhuri is currently attending Stanford University to study data
                            science, after receiving multiple prestigious offers.
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {router.pathname.startsWith('/google-form') ||
                          router.pathname.startsWith('/google-ivy-league') ? (
                            <Button id={buttonId} href="#google-contact">
                              Book Your Free Consultation
                            </Button>
                          ) : (
                            <Button id={buttonId} onClick={redirect}>
                              Book Your Free Consultation
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col className={styles.ugtestiVideoThumb}>
                          <Row>
                            <Col className={styles.ugVideoThumb}>
                              <Image
                                src="/2.jpg"
                                width="500"
                                height="300"
                                //className={}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className={styles.ugVideoIcon}>
                              <div
                                onClick={() => {
                                  handleShowVideo(slideData[0].id)
                                }}
                              >
                                <Image src="/play-button-cp.svg" width="46" height="46" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <Container>
              <Row>
                <Col className={styles.AidenSection}>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col>
                          <h2>PRATIBHA'S SON NIRAJ ATTENDS NYU</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>
                            CollegePass student Niraj has been awarded a scholarship of USD 160,000 and is currently
                            <br /> attending NYU.
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {router.pathname.startsWith('/google-form') ||
                          router.pathname.startsWith('/google-ivy-league') ? (
                            <Button id={buttonId} href="#google-contact">
                              Book Your Free Consultation
                            </Button>
                          ) : (
                            <Button id={buttonId} onClick={redirect}>
                              Book Your Free Consultation
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col className={styles.ugtestiVideoThumb}>
                          <Row>
                            <Col className={styles.ugVideoThumb}>
                              <Image
                                src="/4.jpg"
                                width="500"
                                height="300"
                                //className={}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className={styles.ugVideoIcon}>
                              <div
                                onClick={() => {
                                  handleShowVideo(slideData[2].id)
                                }}
                              >
                                <Image src="/play-button-cp.svg" width="46" height="46" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          {/* <Carousel.Item>
                        <Container>
                            <Row>
                                <Col className={styles.AidenSection}>
                                    <Row>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col>
                                                    <h2>MEDHA HAS BEEN ADMITTED TO LBS
                                                    </h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h3>CollegePass student Medha has been admitted to London Business School in 2024 after receiving offers from other prestigious institutions.</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button id={buttonId} onClick={redirect}>
                                                        Book Your Free Consultation
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col className={styles.ugtestiVideoThumb}>
                                                    <Row>
                                                        <Col className={styles.ugVideoThumb}>
                                                            <Image
                                                                src="/.jpg"
                                                                width="500"
                                                                height="300"
                                                            //className={}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={styles.ugVideoIcon}>
                                                            <div onClick={() => {
                                                                handleShowVideo(slideData[2].id)
                                                            }}
                                                            >
                                                                <Image
                                                                    src="/play-button-cp.svg"
                                                                    width="46"
                                                                    height="46"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item> */}

          <Carousel.Item>
            <Container>
              <Row>
                <Col className={styles.AidenSection}>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col>
                          <h2>TVISHA IS HEADED TO THE IVY LEAGUE</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>
                            CollegePass student Tvisha will attend Columbia University this fall after receiving several
                            offers from other prestigious Ivy League institutions.
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {router.pathname.startsWith('/google-form') ||
                          router.pathname.startsWith('/google-ivy-league') ? (
                            <Button id={buttonId} href="#google-contact">
                              Book Your Free Consultation
                            </Button>
                          ) : (
                            <Button id={buttonId} onClick={redirect}>
                              Book Your Free Consultation
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col className={styles.ugtestiVideoThumb}>
                          <Row>
                            <Col className={styles.ugVideoThumb}>
                              <Image
                                src="/tvisha-modi.png"
                                width="500"
                                height="300"
                                //className={}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className={styles.ugVideoIcon}>
                              <div
                                onClick={() => {
                                  handleShowVideo(slideData[3].id)
                                }}
                              >
                                <Image src="/play-button-cp.svg" width="46" height="46" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>

          <Carousel.Item>
            <Container>
              <Row>
                <Col className={styles.AidenSection}>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col>
                          <h2>ANICIA IS HEADED TO BOSTON UNIVERSITY</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>
                            CollegePass student Anicia will attend Boston University this fall after receiving several
                            offers from other prestigious institutions.
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {router.pathname.startsWith('/google-form') ||
                          router.pathname.startsWith('/google-ivy-league') ? (
                            <Button id={buttonId} href="#google-contact">
                              Book Your Free Consultation
                            </Button>
                          ) : (
                            <Button id={buttonId} onClick={redirect}>
                              Book Your Free Consultation
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col className={styles.ugtestiVideoThumb}>
                          <Row>
                            <Col className={styles.ugVideoThumb}>
                              <Image
                                src="/anicia.png"
                                width="500"
                                height="300"
                                //className={}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className={styles.ugVideoIcon}>
                              <div
                                onClick={() => {
                                  handleShowVideo(slideData[4].id)
                                }}
                              >
                                <Image src="/play-button-cp.svg" width="46" height="46" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item>
            <Container>
              <Row>
                <Col className={styles.AidenSection}>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col>
                          <h2>TULSI IS AT BERKELEY</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>
                            CollegePass student Tulsi received multiple offers from top universities including UCLA,
                            UToronto, UBC, UCL, and chose Berkeley.
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {router.pathname.startsWith('/google-form') ||
                          router.pathname.startsWith('/google-ivy-league') ? (
                            <Button id={buttonId} href="#google-contact">
                              Book Your Free Consultation
                            </Button>
                          ) : (
                            <Button id={buttonId} onClick={redirect}>
                              Book Your Free Consultation
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col className={styles.ugtestiVideoThumb}>
                          <Row>
                            <Col className={styles.ugVideoThumb}>
                              <Image
                                src="/Tulsi.png"
                                width="500"
                                height="300"
                                //className={}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className={styles.ugVideoIcon}>
                              <div
                                onClick={() => {
                                  handleShowVideo(slideData[5].id)
                                }}
                              >
                                <Image src="/play-button-cp.svg" width="46" height="46" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>

          <Carousel.Item>
            <Container>
              <Row>
                <Col className={styles.AidenSection}>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col>
                          <h2>ANUJ’S SON VARUN IS AT NORTHEASTERN</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>
                            Anuj’s son Varun received offers from top universities including UC-Davis, UMass-Amherst,
                            Boston University, and chose Northeastern University.
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {router.pathname.startsWith('/google-form') ||
                          router.pathname.startsWith('/google-ivy-league') ? (
                            <Button id={buttonId} href="#google-contact">
                              Book Your Free Consultation
                            </Button>
                          ) : (
                            <Button id={buttonId} onClick={redirect}>
                              Book Your Free Consultation
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col className={styles.ugtestiVideoThumb}>
                          <Row>
                            <Col className={styles.ugVideoThumb}>
                              <Image
                                src="/Anuj.png"
                                width="500"
                                height="300"
                                //className={}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className={styles.ugVideoIcon}>
                              <div
                                onClick={() => {
                                  handleShowVideo(slideData[6].id)
                                }}
                              >
                                <Image src="/play-button-cp.svg" width="46" height="46" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>

          <Carousel.Item>
            <Container>
              <Row>
                <Col className={styles.AidenSection}>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col>
                          <h2>NITIN’S SON SAHIL ATTENDS UW SEATTLE</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>
                            Nitin’s son Sahil received offers from target universities including UWash-Seattle, Texas
                            A&M, UToronto, and chose University of Washington, Seattle.
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {router.pathname.startsWith('/google-form') ||
                          router.pathname.startsWith('/google-ivy-league') ? (
                            <Button id={buttonId} href="#google-contact">
                              Book Your Free Consultation
                            </Button>
                          ) : (
                            <Button id={buttonId} onClick={redirect}>
                              Book Your Free Consultation
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row>
                        <Col className={styles.ugtestiVideoThumb}>
                          <Row>
                            <Col className={styles.ugVideoThumb}>
                              <Image
                                src="/nitin.png"
                                width="500"
                                height="300"
                                //className={}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className={styles.ugVideoIcon}>
                              <div
                                onClick={() => {
                                  handleShowVideo(slideData[7].id)
                                }}
                              >
                                <Image src="/play-button-cp.svg" width="46" height="46" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>

          {/* <Carousel.Item>
                        <Container>
                            <Row>
                                <Col className={styles.AidenSection}>
                                    <Row>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col>
                                                    <h2>Tvisha is headed to The Ivy League
                                                    </h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h3>CollegePass student Tvisha will be attending Columbia University this fall.</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button id={buttonId} onClick={redirect}>
                                                        Book Your Free Consultation
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col className={styles.ugtestiVideoThumb}>
                                                    <Row>
                                                        <Col className={styles.ugVideoThumb}>
                                                            <Image
                                                                src="/tvisha.png"
                                                                width="500"
                                                                height="300"
                                                            //className={}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={styles.ugVideoIcon}>
                                                            <div onClick={() => {
                                                                handleShowVideo(slideData[8].id)
                                                            }}
                                                            >
                                                                <Image
                                                                    src="/play-button-cp.svg"
                                                                    width="46"
                                                                    height="46"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item> */}
        </Carousel>
      </Container>
      {testimonialID !== undefined && testimonialID !== null && (
        <>
          <div className={styles.testimonialModal + ' ' + styles.fadeIn}>
            <div className={styles.testimonialModalcontainer}>
              <div className={styles.closeIcon} onClick={() => handleCloseVideo()}>
                <i className="fa fa-times"></i>
              </div>
              <iframe
                ref={wrapperRef}
                src={`${testimonialID}`}
                width="900"
                height="430"
                frameBorder="0"
                id="sing_vd"
                allow="autoplay; fullscreen"
                title="video"
                allowFullScreen
                allowtransparency="true"
              />
            </div>
          </div>
          <div className={styles.testimonialModalbg + ' ' + styles.fadeIn}></div>
        </>
      )}
    </Fragment>
  )
}

export default NewTestimonial
